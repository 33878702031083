import React, { useReducer, useEffect } from 'react';
import { useRouter } from 'next/router'
import PropTypes from 'prop-types';
import filter from "lodash/filter";
import find from "lodash/find";
import SlackWebHook from "../services/Slack";
import Validator from "../services/Validation";
import { post } from "../services/Fetch";
import { MdArrowForward } from '@react-icons/all-files/md/MdArrowForward';
import { success, error } from "./Toast";
import mailer from "../services/mail";
import dynamic from "next/dynamic";
const CustomImage = dynamic(() => import('../components/CustomImage'));

const consultationType = [
    {key:'Admissions', value:'admissions'},
    {key:'Career Mapping', value:'career-counselling'},
    {key:'Standardized Tests', value:'standardized-tests'},
    // {key:'SAT', value:'sat'},
    // {key:'ACT', value:'act'},
]

const initState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    countryId: 101,
    gender: 'other',
    type: '',
    location:'',
    description: '',

    validator: false,
    loading: false,
    success: false,
};

const reducer = (state, action) => {
    switch (action.type) {
    case 'ONCHANGE': {
        const { target } = action.payload;
        return {
            ...state,
            [target.name]: target.value,
            loading: false,
            validator: false,
        };
    }
    case 'COUNTRY': {
        return {
            ...state,
            countryId: action.payload
        };
    }
    case 'LOCATION': {
        return {
            ...state,
            location: action.payload
        };
    }
    case 'LOAD': {
        return {
            ...state,
            loading: action.payload
        };
    }
    case 'VALIDATE': {
        return {
            ...state,
            validator: action.payload
        };
    }
    case 'SUCCESS': {
        return {
            ...state,
            success: action.payload
        };
    }
    case 'RESET': {
        return {
            ...state,
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            countryId: 101,
            gender: 'other',
            type: '',
            location:'',
            description: '',

            validator: false,
            loading: false,
            success: false,
        };
    }
    default: {
        return { ...state };
    }
    }
};

export default function LeadCollectionForm({ location, countryList, courseId, trigger, close, next, query, referralCode, consultType }) {
    const [state, dispatch] = useReducer(reducer, initState);
    const router = useRouter();

    useEffect(() => {
        if(location && countryList.length > 0) {
            var cntryId = find(countryList, ['code', location.country_code || 'US'])?.countryId;
            dispatch({ type: 'COUNTRY', payload: cntryId });
            dispatch({ type: 'LOCATION', payload: location.city });
        }
    }, [countryList, location]);

    useEffect(() => {
        dispatch({ type: 'ONCHANGE', payload: {target: {name: 'type', value: consultType}} });
    }, [consultType]);

    const validator = () => {
        return new Promise((resolve, reject) => {
            try{
                if(Validator.text(state.firstName.trim())
                    && Validator.text(state.lastName.trim())
                    && Validator.email(state.email.trim())
                    // && Validator.text(state.gender)
                    && Validator.positiveNumber(state.countryId)
                    && Validator.text(state.type)
                    && Validator.text(state.location)
                    && (state.countryId == 101 || process.env.NEXT_PUBLIC_SOURCE === "WETUTOR" ? Validator.mobile(state.mobile.trim()) : (state.mobile ? Validator.mobile(state.mobile.trim()) : true))){
                    resolve(true);
                }else {
                    resolve(false);
                }
            }catch(err){
                reject(err)
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch({ type: 'LOAD', payload: true });
        dispatch({ type: 'VALIDATE', payload: true });
        validator().then((res) => {
            if(res){
                const { firstName, lastName, email, mobile, countryId, type } = state;
                let input = {
                    name: state.firstName + " " + state.lastName,
                    email: state.email,
                    mobile: state.mobile !== '' ? `+${location.calling_code} ${state.mobile}` : '',
                    location: state.location,
                    description: "Request For: " + (find(consultationType, ['value', type]).key).toUpperCase() + " - " + state.description,
                    contactType: type
                };
                let message = `${find(consultationType, ['value', type]).key} : ${firstName} ${lastName} (${email} ${mobile != "" ? find(countryList, ['countryId', parseInt(countryId)]).dialCode + ' ' + mobile : ""}) requested from ${location.city}, ${location.country_name}`
                SlackWebHook.sendSlackMessage(message, `ICICI Lead for ${find(consultationType, ['value', type]).key}` , ':walking::skin-tone-4:', process.env.NEXT_PUBLIC_ICICI_SLACK_CHANNEL);
                post(`${process.env.NEXT_PUBLIC_API_URI}${process.env.NEXT_PUBLIC_API_VERSION}/contactUs?_format=json`, input, {}).then((res) => {
                    if (res.code === "201" && res.status === "success") {
                        let data = {
                            fullName: state.firstName + " " + state.lastName
                        }
                        mailer.sendMail(state.email, "contactUs-mail", [], [], "You have Sent a Request.", false, data).then((res) => {
                            if (res.status === 'success' && res.code === '250') {
                                console.log("mail-sent");
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                        let adminData = {
                            fullName: state.firstName + " " + state.lastName,
                            mail: state.email,
                            phone: mobile !== '' ? `+${location.calling_code} ${mobile}` : '',
                            location: state.location,
                            message: "ICICI Lead Request For: " + (find(consultationType, ['value', type]).key).toUpperCase() + " - " + state.description,
                        }
                        mailer.sendMail(process.env.NEXT_PUBLIC_ADMIN_EMAIL, "contactUsAdmin-mail", [], [], "New Contact Request", false, adminData).then((res) => {
                            if (res.status === 'success' && res.code === '250') {
                                console.log("mail-sent-admin");
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                        dispatch({ type: 'SUCCESS', payload: true });
                        dispatch({ type: 'LOAD', payload: false });

                    } else {
                        dispatch({ type: 'LOAD', payload: false });
                        error('Something went wrong.');
                    }
                }).catch(() => {
                    dispatch({ type: 'LOAD', payload: false });
                    error('Something went wrong.');
                    SlackWebHook.sendSlackMessage(err.message)
                });
            }else{
                dispatch({ type: 'LOAD', payload: false });
                error("Please fill the mandatory fields");
            }
        });
    }

    const registration = () => {
        dispatch({ type: 'LOAD', payload: true });
        const { firstName, lastName, email, mobile, countryId, gender } = state;
        const input = {
            "firstName": firstName.trim(),
            "lastName": lastName.trim(),
            "countryId": parseInt(countryId),
            "email": email.trim(),
            "mobile": mobile.trim(),
            "password": btoa(new Date().getTime()).replace(/=/g,'').substring(8),
            "roleMachineName": "users",
            "gender": gender,
            "disabilityStatus": 0,
            "userDisability": "",
            "referralCode": referralCode,
            "courseId": courseId,
            "courseExamDate": "",
            "currentGrade": "",
            "schoolBoard": "",
            "schoolName": "",
            "utmSource": "static-registration",
        };

        post(`${process.env.NEXT_PUBLIC_API_URI}v1/user/frontEnd/registration?_format=json`, input, {}).then((res) => {
            let response = res;
            if(response.code === "201" && response.status === "success") {
                let message = `${input.firstName} ${input.lastName} (${input.email} ${input.mobile != "" ? find(countryList, ['countryId', parseInt(input.countryId)]).dialCode + ' ' + input.mobile : ""}) registered from ${location.city}, ${location.country_name}, URL: ${window.location.href}`
                SlackWebHook.sendSlackMessage(message, 'ICICI Leads', ':walking::skin-tone-4:');
                let data = {
                    firstName: input.firstName,
                    lastName: input.lastName,
                    password: input.password,
                    actionUrl: `${process.env.NEXT_PUBLIC_FRONT_URI}auth?type=${btoa("email")}&token=${btoa(process.env.NEXT_PUBLIC_CLG_API_KEY)}&email=${btoa(input.email)}${input.courseId ? '&force=true&cid=' + btoa(input.courseId) : ''}&next=${btoa('/dashboard')}`,
                }
                mailer.sendMail(input.email, "frontendRegistration-mail", [], [], `Welcome to ${process.env.NEXT_PUBLIC_APP_NAME}`, false, data).then((res) => {
                    if (res.status === 'success' && res.code === '250') {
                        console.log("mail-sent");
                    }
                }).catch((err) => {
                    console.log(err);
                });
                // success(`Thank you for your interest.`);
                window.location.replace(data.actionUrl, '_blank');
            }else {
                dispatch({ type: 'LOAD', payload: false });
                if(response.message === 'user already exist with same email') {
                    success(`Thank you for your interest.`);
                    dispatch({ type:'RESET' });
                    // window.location.replace(`${process.env.NEXT_PUBLIC_FRONT_URI}auth?type=${btoa("email")}&token=${btoa(process.env.NEXT_PUBLIC_CLG_API_KEY)}&email=${btoa(input.email)}${input.courseId ? '&force=true&cid=' + btoa(input.courseId) : ''}&next=${btoa('/checkout')}`, '_blank');
                } else {
                    error(response.message);
                }
            }
        }).catch((err) => {
            dispatch({ type: 'LOAD', payload: false });
            error(err.message);
            SlackWebHook.sendSlackMessage(err.message)
        });
    }

    const getter = (e) => {
        e.persist();
        dispatch({ type: 'ONCHANGE', payload: e })
    }

    return (
        <>
        {
            state.success?
            <section className="nav-margin intro-img mx-auto py-5 container">
                <div className="row d-block">
                    <div className="col-md-6 mx-auto text-center">
                    <CustomImage srcSetPaths={[{path: '/static/success.png?tr=w-250,f-avif', type: 'image/avif'},{path: '/static/success.png?tr=w-250,f-auto', type: 'image/webp'}]}
                    mainSrc="/static/success.png?tr=w-250,f-png" alt="Success Icon" width="250px" height="auto" imgCDN loading="eager" className="darken mx-auto" />
                    </div>
                    <h3 className="text-center my-3">Thank You! {state.firstName} {state.lastName} </h3>
                    <h5 className="text-center">We’ve sent an email to {state.email}<br /> Please check your Inbox</h5>
                </div>
                {
                    <div className={`flex-column flex-md-row align-items-center mt-4 justify-content-center`}>
                        {
                        state.type === 'standardized-tests' ?
                        <button type="submit"
                            className="btn btn-primary text-white btn-with-icon"
                            disabled={state.loading}
                            onClick={registration}
                        >
                            Continue to Dashboard
                            <span className="btn-icon ms-1">
                                {
                                    state.loading?
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    :
                                    <MdArrowForward fontSize="22px" />
                                }
                            </span>
                        </button>
                        :
                        <button type="submit"
                            data-bs-dismiss="modal"
                            className="btn btn-primary text-white"
                            aria-label="Close"
                            onClick={() => dispatch({ type: 'RESET', payload: true })}
                        >
                            Close
                        </button>
                        }
                    </div>
                }
            </section>
            :
                <form className="registration-form" id="sat-campaign-form">
                    <div className="row g-3 mb-2">
                        <div className="form-group col-md-6">
                            <label htmlFor="firstName" className="col-form-label">First Name</label>
                            <input type="text" name="firstName" id="firstName"
                                onChange={getter}
                                className={`form-control ${(state.validator && (Validator.text(state.firstName.trim()) ? '' : 'error')).toString()}`}
                                placeholder="First Name"
                                value={state.firstName}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="lastName" className="col-form-label">Last Name</label>
                            <input type="text" name="lastName" id="lastName"
                                onChange={getter}
                                className={`form-control ${(state.validator && (Validator.text(state.lastName.trim()) ? '' : 'error')).toString()}`}
                                placeholder="Last Name"
                                value={state.lastName}
                            />
                        </div>
                    </div>
                    <div className="row g-3 mb-2">
                        {/* <div className="form-group col-md-6">
                            <label htmlFor="gender" className="col-form-label">Gender</label>
                            <select id="gender"
                                onChange={getter}
                                value={state.gender}
                                className={`form-select ${(state.validator && (Validator.text(state.gender.trim()) ? '' : 'error')).toString()}`}
                                name="gender">
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Others</option>
                            </select>
                        </div> */}
                        <div className="form-group col-md-6">
                            <label htmlFor="email" className="col-form-label">Email</label>
                            <input type="email" name="email" id="email"
                                className={`form-control ${(state.validator && (Validator.email(state.email.trim()) ? '' : 'error')).toString()}`}
                                placeholder="Enter your Email"
                                onChange={getter}
                                value={state.email}
                            />
                        </div>
                        <div className={`form-group col-md-6`}>
                            <label htmlFor="mobile" className="col-form-label">
                            Contact Number
                            </label>
                            <div className="position-relative">
                                <input type="text"
                                    className={`form-control mobile ${(state.validator && (state.countryId == 101 || process.env.NEXT_PUBLIC_SOURCE === "WETUTOR" ? (Validator.mobile(state.mobile.trim()) ? '' : 'error') : (state.mobile ? (Validator.mobile(state.mobile.trim()) ? '' : 'error') : ''))).toString()}`}
                                    placeholder="Phone Number"
                                    name="mobile" id="mobile"
                                    onChange={getter}
                                    value={state.mobile}
                                />
                                <p className="c-code txt-16--body lh-20">{filter(countryList, ['countryId', parseInt(state.countryId)]).length > 0 ? filter(countryList, ['countryId', parseInt(state.countryId)])[0].dialCode : '+91'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 mb-2">
                        {/* <div className={`form-group col-md-6`}>
                            <label className="col-form-label" htmlFor="country">Select Country</label>
                            <select id="country"
                                onChange={getter}
                                value={state.countryId}
                                className={`form-select`}
                                name="countryId">
                                <option disabled>Select Country</option>
                                {state.countryId != null && countryList.length > 0 && countryList.map((country, index) =>
                                    <option
                                        value={country.countryId}
                                        defaultValue={country.countryId == state.countryId ? true : false }
                                        key={index}>
                                        {country.name}
                                    </option>
                                )}
                            </select>
                        </div> */}

                        {/* <div className={`form-group col-md-6`}>
                            <label htmlFor="mobile" className="col-form-label">
                            Contact Number
                            </label>
                            <div className="position-relative">
                                <input type="text"
                                    className={`form-control mobile ${(state.validator && (state.countryId == 101 || process.env.NEXT_PUBLIC_SOURCE === "WETUTOR" ? (Validator.mobile(state.mobile.trim()) ? '' : 'error') : (state.mobile ? (Validator.mobile(state.mobile.trim()) ? '' : 'error') : ''))).toString()}`}
                                    placeholder="Phone Number"
                                    name="mobile" id="mobile"
                                    onChange={getter}
                                    value={state.mobile}
                                />
                                <p className="c-code txt-16--body lh-20">{filter(countryList, ['countryId', parseInt(state.countryId)]).length > 0 ? filter(countryList, ['countryId', parseInt(state.countryId)])[0].dialCode : '+91'}</p>
                            </div>
                        </div> */}
                        <div className={`form-group col-md-6`}>
                            <label className="col-form-label" htmlFor="type">Choose Service</label>
                            <select id="type"
                                onChange={getter}
                                value={state.type}
                                className={`form-select ${(state.validator && (Validator.text(state.type.trim()) ? '' : 'error')).toString()}`}
                                name="type">
                                <option value={""} disabled>Select Service Type</option>
                                {consultationType.map((value, key) =>
                                    <option
                                        value={value.value}
                                        key={key}>
                                        {value.key}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="location" className="col-form-label">Location</label>
                            <input type="text" name="location" id="location"
                                onChange={getter}
                                className={`form-control ${(state.validator && (Validator.text(state.location.trim()) ? '' : 'error')).toString()}`}
                                placeholder="Enter City"
                                value={state.location}
                            />
                        </div>
                    </div>
                    <div className='row g-3 mb-2'>
                        <div className="form-group">
                            <label htmlFor="description" className="col-form-label">What can we help you with? (optional)</label>
                            <textarea onChange={getter} value={state.description} rows="6" className="form-control" id="description" name="description" placeholder="Give us a short description of your requirement and we'll get back to you."></textarea>
                        </div>
                    </div>
                    <div className={`flex-column flex-md-row justify-content-md-between align-items-center mt-5 ${trigger === 'page' ? 'justify-content-center' : 'justify-content-end'}`}>
                        {
                            trigger !== 'page' &&
                            <a href="#" className="bpointer d-none d-md-block text-body pe-auto fw-bold" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch({ type: 'RESET', payload: true })}>Cancel</a>
                        }
                        <button type="submit"
                            className="btn btn-primary text-white btn-with-icon"
                            disabled={state.loading}
                            onClick={onSubmit}
                        >
                        {/* Send Message */}
                        Submit
                            <span className="btn-icon ms-1">
                            {
                                state.loading?
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                :
                                <MdArrowForward fontSize="22px" />
                            }
                            </span>
                        </button>
                    </div>
                </form>
            }
        </>
    )
}

LeadCollectionForm.propTypes = {
    courseId: PropTypes.number,
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    countryList: PropTypes.array,
    trigger: PropTypes.string,
    next: PropTypes.string,
    query: PropTypes.object,
    router: PropTypes.object,
    referralCode: PropTypes.string,
    consultType: PropTypes.string,
}

LeadCollectionForm.defaultProps = {
    trigger: 'modal',
    next: '/registration-success',
    query: {},
    courseId: null,
    referralCode: process.env.NEXT_PUBLIC_REFERRAL_CODE,
    consultType: ''
}