import React from 'react'

const SATIndex = (props) => {
    return (
        <svg width="534" height="357" viewBox="0 0 534 357" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip-sat-index)">
                <path d="M220.464 97.2745C250.17 93.976 277.814 82.3838 304.106 70.0013C330.396 57.6189 356.344 44.1429 384.994 36.4545C403.42 31.5091 424.768 29.3734 440.59 38.037C455.823 46.392 461.768 62.3293 465.456 77.2743C468.232 88.5164 470.272 100.413 465.902 111.473C462.87 119.152 456.95 125.864 453.077 133.316C439.581 159.165 452.817 188.751 471.371 211.557C480.069 222.26 489.992 232.35 496.959 243.906C503.927 255.461 507.791 269.036 503.457 281.713C499.156 294.286 487.376 304.368 474.352 311.829C447.89 326.986 415.822 333.2 384.41 337.735C314.898 347.776 244.499 351.133 174.284 354.476C148.298 355.712 122.2 356.946 96.3921 354.976C82.0577 353.88 67.17 351.543 56.2375 343.974C42.366 334.367 37.7419 316.651 45.4577 302.682C58.4087 279.246 98.5296 270.597 106.95 245.862C111.573 232.253 104.64 218.019 95.4931 206.422C75.8675 181.553 44.5941 160.809 40.7206 130.959C38.0616 110.453 50.6516 88.755 71.8225 77.3318C94.0089 65.3232 125.983 64.1775 144.235 79.7788C163.042 95.8548 194.237 100.183 220.464 97.2745Z" fill="#E7F2FF" fillOpacity="0.8" />
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="534" height="357">
                    <path d="M204.314 55.2232C234.02 51.9247 256.554 58.8799 282.846 46.4974C309.136 34.115 348.434 12.8837 377.084 5.19533C395.511 0.249873 435.14 -3.46825 450.962 5.19537C466.196 13.5503 490.903 23.0919 494.591 38.0369C497.367 49.279 508.973 86.2144 504.603 97.2745C501.571 104.953 515.915 108.851 512.043 116.304C498.546 142.152 495.234 145.852 513.788 168.658C522.485 179.361 526.017 212.366 532.985 223.922C539.952 235.477 508.937 269.036 504.603 281.713C500.302 294.286 488.522 304.367 475.498 311.829C449.036 326.985 416.967 333.199 385.556 337.735C316.044 347.776 245.645 351.133 175.43 354.476C149.444 355.712 123.346 356.946 97.538 354.975C83.2036 353.879 68.3159 351.543 57.3835 343.974C43.5119 334.367 38.8878 316.651 46.6036 302.682C59.5546 279.246 30.1036 281.814 38.5236 257.08C43.1468 243.471 54.6507 237.264 45.5042 225.667C25.8786 200.798 4.00348 167.096 0.130066 137.246C-2.52893 116.739 36.2124 72.4636 57.3835 61.0405C79.5698 49.0319 117.419 35.5498 135.671 51.1511C154.478 67.2271 178.087 58.132 204.314 55.2232Z" fill="var(--color-secondary-light)" />
                </mask>
                <g mask="url(#mask0)">
                    <path d="M150.388 177.25L149.513 365.424C149.507 366.666 160.678 367.673 174.465 367.673C188.251 367.674 199.432 366.668 199.437 365.426L200.313 177.251C200.319 176.009 189.147 175.002 175.361 175.002C161.575 175.001 150.394 176.008 150.388 177.25Z" fill="var(--color-secondary-light)" />
                    <path d="M243.104 267.336L242.731 365.038C242.725 366.499 253.897 367.683 267.683 367.684C281.469 367.684 292.65 366.501 292.655 365.04L293.029 267.338C293.034 265.877 281.863 264.693 268.076 264.692C254.29 264.692 243.11 265.875 243.104 267.336Z" fill="var(--color-secondary-light)" />
                    <path d="M335.894 111.386L334.932 365.047C334.926 366.507 346.098 367.692 359.884 367.692C373.671 367.693 384.851 366.509 384.857 365.049L385.818 111.388C385.824 109.928 374.652 108.743 360.866 108.743C347.079 108.742 335.899 109.925 335.894 111.386Z" fill="var(--color-secondary)" />
                    <path d="M359.77 79.2996C371.79 79.2996 381.534 69.5332 381.534 57.4858C381.534 45.4383 371.79 35.6719 359.77 35.6719C347.75 35.6719 338.006 45.4383 338.006 57.4858C338.006 69.5332 347.75 79.2996 359.77 79.2996Z" fill="white" />
                    <path d="M359.769 31.9545C354.625 31.9545 349.596 33.4807 345.318 36.3401C341.041 39.1996 337.707 43.2638 335.738 48.0188C333.77 52.7739 333.255 58.0062 334.258 63.0541C335.262 68.1021 337.739 72.7389 341.377 76.3783C345.014 80.0176 349.649 82.4961 354.695 83.5002C359.74 84.5043 364.97 83.989 369.723 82.0193C374.476 80.0497 378.538 76.7143 381.396 72.4349C384.254 68.1554 385.78 63.1242 385.78 57.9773C385.76 51.0819 383.013 44.4745 378.139 39.5987C373.266 34.7228 366.661 31.9747 359.769 31.9545ZM354.429 71.852L341.099 58.5153L344.838 54.7745L354.442 64.383L374.713 44.1026L378.452 47.8434L354.429 71.852Z" fill="var(--color-secondary)" />
                    <path d="M429.401 160.632L428.819 364.194C428.814 366.13 439.985 367.699 453.772 367.7C467.558 367.701 478.738 366.133 478.744 364.197L479.326 160.635C479.331 158.699 468.16 157.13 454.374 157.129C440.587 157.128 429.407 158.696 429.401 160.632Z" fill="var(--color-secondary-light)" />
                    <path d="M57.0688 253.747L56.8635 365.856C56.8617 366.855 68.0361 367.666 81.8224 367.667C95.6087 367.668 106.786 366.86 106.788 365.861L106.993 253.752C106.995 252.753 95.8206 251.942 82.0343 251.941C68.248 251.94 57.0706 252.748 57.0688 253.747Z" fill="var(--color-secondary-light)" />
                </g>
            </g>
            <defs>
                <clipPath id="clip-sat-index">
                    <rect width="534" height="356.554" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SATIndex;