import React from 'react'

const ACTIndex = (props) => {
    return (
        <svg width="569" height="342" viewBox="0 0 569 342" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M304.038 100.426C276.569 97.3676 251.007 86.6209 226.695 75.1416C202.384 63.6624 178.391 51.1693 151.899 44.0417C134.86 39.457 115.121 37.477 100.49 45.5087C86.4041 53.2543 80.9072 68.0292 77.4969 81.8841C74.9298 92.3062 73.0433 103.335 77.0848 113.589C79.8879 120.707 85.3624 126.929 88.9433 133.838C101.423 157.802 89.184 185.229 72.0271 206.373C63.9844 216.294 54.8086 225.649 48.366 236.362C41.9235 247.074 38.3502 259.659 42.3582 271.411C46.335 283.067 57.2279 292.413 69.2711 299.33C93.7396 313.382 123.393 319.142 152.439 323.347C216.716 332.656 281.812 335.768 346.738 338.867C370.768 340.013 394.9 341.157 418.765 339.33C432.02 338.314 445.786 336.148 455.895 329.131C468.722 320.225 472.998 303.801 465.863 290.851C453.888 269.124 416.789 261.106 409.003 238.175C404.728 225.559 411.139 212.363 419.596 201.612C437.744 178.557 466.662 159.326 470.244 131.653C472.702 112.643 461.061 92.5274 441.484 81.9374C420.969 70.8047 391.402 69.7426 374.525 84.206C357.134 99.1094 328.29 103.122 304.038 100.426Z" fill="var(--color-primary-light)" />
            <mask id="mask-act-index" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="569" height="341">
                <path d="M317.169 40.0422C289.7 36.9843 276.48 39.5214 252.169 28.0421C227.857 16.5629 192.661 8.1697 166.169 1.04215C149.13 -3.54259 119.299 8.01048 104.669 16.0422C90.5829 23.7878 54.5793 26.1872 51.1689 40.0422C48.6018 50.4642 47.1274 73.9526 51.1689 84.206C53.9719 91.3243 38.7773 100.133 42.3581 107.042C30.4997 126.246 18.8258 131.899 1.66896 153.042C-6.37373 162.964 17.6115 211.829 11.1689 222.542C4.72646 233.255 38.3502 259.659 42.3581 271.411C46.3349 283.067 57.2278 292.413 69.2711 299.33C93.7395 313.382 123.392 319.143 152.439 323.347C216.715 332.656 281.812 335.768 346.738 338.867C370.768 340.013 394.9 341.157 418.765 339.33C432.02 338.314 445.786 336.148 455.895 329.131C468.722 320.225 539.804 297.992 532.669 285.042C520.693 263.316 523.955 263.973 516.169 241.042C511.894 228.426 524.211 212.293 532.669 201.542C550.816 178.487 564.587 158.715 568.169 131.042C570.628 112.031 495.745 55.1321 476.169 44.5421C455.654 33.4094 411.546 25.5788 394.669 40.0422C377.279 54.9456 341.421 42.7388 317.169 40.0422Z" fill="#FFFAE5" />
            </mask>
            <g mask="url(#mask-act-index)">
                <path d="M233.577 179.042H78.6691V382.521H233.577V179.042Z" fill="#FBD9D9" />
                <path d="M210.92 247.045H101.326V259.696H210.92V247.045Z" fill="#FFA9A9" />
                <path d="M210.92 274.456H101.326V287.108H210.92V274.456Z" fill="#FFA9A9" />
                <path d="M210.92 301.868H101.326V314.519H210.92V301.868Z" fill="#FFA9A9" />
                <path d="M350.669 109.042H195.669V292.042H350.669V109.042Z" fill="#388E3C" />
                <path d="M328.187 136.042H218.593V148.694H328.187V136.042Z" fill="#8BFE90" />
                <path d="M328.187 163.454H218.593V176.106H328.187V163.454Z" fill="#8BFE90" />
                <path d="M328.187 190.866H218.593V203.517H328.187V190.866Z" fill="#8BFE90" />
                <path d="M328.187 218.278H218.593V230.929H328.187V218.278Z" fill="#8BFE90" />
                <path d="M328.187 245.689H218.593V258.341H328.187V245.689Z" fill="#8BFE90" />
                <path d="M273.026 89.7364C283.357 89.7364 291.733 81.342 291.733 70.9869C291.733 60.6319 283.357 52.2374 273.026 52.2374C262.695 52.2374 254.319 60.6319 254.319 70.9869C254.319 81.342 262.695 89.7364 273.026 89.7364Z" fill="white" />
                <path d="M273.026 49.0422C268.604 49.0422 264.281 50.3541 260.605 52.8118C256.928 55.2696 254.063 58.7628 252.371 62.8499C250.679 66.937 250.236 71.4343 251.099 75.7731C251.961 80.1119 254.09 84.0973 257.217 87.2254C260.344 90.3536 264.327 92.4838 268.664 93.3469C273.001 94.2099 277.496 93.767 281.581 92.0741C285.666 90.3811 289.158 87.5143 291.615 83.836C294.071 80.1577 295.382 75.8333 295.382 71.4095C295.365 65.4826 293.004 59.8035 288.815 55.6126C284.626 51.4217 278.95 49.0596 273.026 49.0422ZM268.436 83.335L256.978 71.8718L260.192 68.6566L268.447 76.9152L285.87 59.4839L289.084 62.6991L268.436 83.335Z" fill="#429446" />
                <path d="M453.577 187.042H298.669V390.521H453.577V187.042Z" fill="#D1FCFF" />
                <path d="M410.898 219.198H341.348V288.781H410.898V219.198Z" fill="var(--color-secondary)" fillOpacity="0.4" />
                <path d="M437.244 303.541H315.003V311.975H437.244V303.541Z" fill="var(--color-secondary)" fillOpacity="0.4" />
                <path d="M437.244 326.735H315.003V335.17H437.244V326.735Z" fill="var(--color-secondary)" fillOpacity="0.4" />
            </g>
        </svg>



    )
}

export default ACTIndex;